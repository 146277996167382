import React, { Component } from "react";
import "./bootstrap-space.css";
import "./App.css";
import "./animate.css";
import "./icons.css";
import LoginForm from "./Modules/Login/LoginForm";
import LogOut from "./Modules/Login/LogOut";
import { Route, Routes, Navigate } from "react-router-dom";
import Main from "./Modules/Main/Main";
// import Dashboard from "./Modules/Dashboard/Dashboard"
import ServerList from "./Modules/Servers/ServerList";
import ModuleList from "./Modules/Modules/ModuleList";
import ServerPermissionList from "./Modules/ServerPermissions/ServerPermissionList";
import Server from "./Modules/Server/Server";
import UserList from "./Modules/Users/UserList.js";
import EnotFound from "./Modules/Errors/EnotFound";
import About from "./Modules/About/About";
import Guide from "./Modules/Guide/Guide";
import Test from "./Modules/Test/Test";

import { ToastContainer } from "react-toastify";

export default class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoginIn: localStorage?.token?.length ? true : false,
    };
  }

  setLoginStatus = (loginStatus) => {
    this.setState({ isLoginIn: loginStatus });
  };

  render() {
    return (
      <div className="App" id="App">
        {this.state.isLoginIn ? (
          <Main>
            <Routes>
              <>
                <Route path="/test" element={<Test />} />
                <Route
                  path="/logout"
                  element={<LogOut setLoginStatus={this.setLoginStatus} />}
                />
                {/* <Route path="/dashboard" element={<Dashboard />} /> */}
                <Route path="/guide" element={<Guide />} />
                <Route path="/about" element={<About />} />
                <Route path="/servers" element={<ServerList />} />
                <Route path="/modules" element={<ModuleList />} />
                <Route
                  path="/server-permissions"
                  element={<ServerPermissionList />}
                />
                <Route exact path="/server/:server/:act" element={<Server />} />
                <Route exact path="/server/:server" element={<Server />} />
                <Route exact path="/users" element={<UserList />} />
                <Route exact path="/" element={<Navigate to="/servers" />} />
                <Route path="/login" element={<Navigate to="/servers" />} />
                <Route path="*" element={<EnotFound />} />
              </>
            </Routes>
          </Main>
        ) : (
          <Routes>
            <Route path="/test" element={<Test />} />
            <Route path="*" element={<LoginForm setLoginStatus={this.setLoginStatus} />} />
          </Routes>
        )}
        <ToastContainer
          theme="colored"
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    );
  }
}
