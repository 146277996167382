import BaseForm from "../BaseForm";

import React from "react";
import { Button, Form, Modal, Nav, Row } from "react-bootstrap";

import FormControlsRender from "../../../Utils/FormControlsRender";
import { Text, TextArea, DatePicker } from "../../../Utils/FormControlsPresets";
import SearchInputObj from "../../../Components/General/SearchInputs/searchInputObj";
import Tab from "../../../Components/General/Tab";
import Guid from "../../../Components/Guid";
import Zone from "../../../Components/Zone";
import ConfirmModal from "../../../Components/General/ConfirmModal";
import Sensor from "../../../Components/Devices/Sensor";
import { default as DP } from "../../../Components/General/DatePicker";
import {
  METHOD_POST,
  METHOD_PUT,
  RequestWithToken,
} from "../../../Components/General/Request";
import { toast } from "react-toastify";
import { formatDate } from "../../../Utils/Helpers";

export default class PointForm extends BaseForm {
  constructor(props) {
    super(props);

    this.state = {
      navKey: "1",
      requestProcess: false,
      card: {},
      formControls: {
        TITLE: Text("Наименование"),
        PRIM: TextArea("Примечание"),
        DATEON: DatePicker("Дата создания"),
        ZONE_ID: {},
      },
      postData: {},
      sensorModal: {},
      // sensorModalErrors: {},
    };

    this.id = props.id; //для обновления ID
  }

  SensorRow = ({ idLink, serial, dateFrom, dateTo }) => {
    const dateFromFormatted = new Date(dateFrom).toLocaleDateString("ru-RU");
    const dateToFormatted = dateTo
      ? new Date(dateTo).toLocaleDateString("ru-RU")
      : "";

    return (
      <div
        style={{ cursor: "pointer" }}
        onClick={() =>
          this.setState({
            sensorModal: {
              idLink,
              serial,
              dateFrom: formatDate(dateFrom),
              dateTo: dateTo ? formatDate(dateTo) : null,
            },
          })
        }
      >
        <span
          style={{ display: "inline-block", width: 200 }}
          title="Серийный номер"
        >
          {serial}
        </span>
        <span
          style={{ display: "inline-block", width: 200 }}
          title="Дата установки"
        >
          {dateFromFormatted}
        </span>
        <span
          style={{ display: "inline-block", width: 200 }}
          title="Дата снятия"
        >
          {dateToFormatted}
        </span>
      </div>
    );
  };

  savePointSensor = () => {
    this.setState({ requestProcess: true, sensorModalErrors: {} });

    let url = `retranslate/${this.props.server}/point-sensor`;
    let method = METHOD_POST;

    if (this.state.sensorModal.idLink) {
      url = `${url}/${this.state.sensorModal.idLink}`;
      method = METHOD_PUT;
    }

    const data = {
      SENSOR_ID: this.state.sensorModal.id,
      POINT_ID: this.id,
      DATE_FROM: this.state.sensorModal.dateFrom,
      DATE_TO: this.state.sensorModal.dateTo,
    };

    RequestWithToken(url)
      .setData(data)
      .fetch(method)
      .then(() => {
        this.loadAndParentReload(this.id, ["SENSORS"]);
        toast.success(`Успешно`);
        this.setState({ sensorModal: {} });
      })
      .catch((res) => {
        // this.setState({ sensorModalErrors: res.response?.data?.errors }, () => console.log(this.state.sensorModalErrors?.DATE_TO?.length ))
        toast.error("Ошибка запроса");
      })
      .finally(() => this.setState({ requestProcess: false }));
  };

  render() {
    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title>{`${this.id ? "Редактирование" : "Создание"} точки учета`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Guid key={this.id} value={this.id} />
            </Row>
            <Row>
              {FormControlsRender(
                "TITLE",
                this.state.formControls,
                this.onChangeHandler,
                this.onSaveHandler(),
                "col-md-4",
              )}
              <SearchInputObj
                label="Зона"
                source="zone"
                server={this.props.server}
                render={(item) => <Zone item={item} />}
                onSelectItem={(item) => {
                  this.setState({
                    postData: Object.assign({}, this.state.postData, {
                      ZONE_ID: item.ID ?? null,
                    }),
                  });
                }}
                defaultItem={{
                  ID: this.state.card.ZONE?.ID,
                  TITLE: this.state.card.ZONE?.TITLE,
                }}
                errorMessage={this.state.formControls.ZONE_ID?.errorMessage}
              />
              {FormControlsRender(
                "DATEON",
                this.state.formControls,
                this.onChangeDPHandler,
                this.onSaveHandler(),
                "col-md-4",
                { disabled: true },
              )}
            </Row>
            <Row>
              {FormControlsRender(
                "PRIM",
                this.state.formControls,
                this.onChangeHandler,
                this.onSaveHandler(),
                "col-md-12",
              )}
            </Row>
          </Form>

          <Nav
            variant="tabs"
            defaultActiveKey={this.state.navKey}
            onSelect={(key) => this.setState({ navKey: key })}
          >
            <Nav.Item>
              <Nav.Link eventKey="1">Датчики</Nav.Link>
            </Nav.Item>
          </Nav>

          <div className="tab-content tabContent" id="nav-tabContent">
            <span
              style={{ display: this.state.navKey !== "1" ? "none" : null }}
            >
              <Tab
                cards={this.state.card?.SENSORS ?? []}
                onChangeHandler={() =>
                  this.loadAndParentReload(this.id, ["SENSORS"])
                }
                renderFunction={(item) => (
                  <this.SensorRow
                    idLink={item.ID_LINK}
                    serial={item.SERIAL_NUMBER}
                    dateFrom={item.DATE_FROM}
                    dateTo={item.DATE_TO}
                  />
                )}
                renderSearchFunction={(item) => <Sensor item={item} />}
                onSearchSelect={(item) =>
                  this.setState({
                    sensorModal: { id: item.ID, serial: item.SERIAL_NUMBER },
                  })
                }
                showAddButton={true}
                deleteUrl={(item) =>
                  `retranslate/${this.props.server}/point-sensor/${item.ID_LINK}`
                }
                searchUrl={`retranslate/${this.props.server}/sensor`}
                filter={{ noLs: this.id ?? null }}
                disabled={!this.id}
              />
            </span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {this.id ? (
            <Button
              disabled={this.state.requestProcess}
              variant="danger"
              onClick={this.delClick}
            >
              Удалить
            </Button>
          ) : null}
          <Button
            disabled={this.disabledSaveButtons()}
            variant="primary"
            onClick={this.saveClick}
          >
            Сохранить и продолжить
          </Button>
          <Button
            disabled={this.disabledSaveButtons()}
            variant="primary"
            onClick={this.saveCloseClick}
          >
            Сохранить и закрыть
          </Button>
          <Button
            disabled={this.state.requestProcess}
            variant="secondary"
            onClick={this.cancelClick}
          >
            Отмена
          </Button>
        </Modal.Footer>

        <Modal
          show={this.state.sensorModal.id || this.state.sensorModal.idLink}
          onHide={this.hideModals}
          size="md"
        >
          <Modal.Header>
            <Modal.Title>{"Точка учета - термогигрометр"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Серийный номер устройства: {this.state.sensorModal.serial}</p>
            <DP
              value={this.state.sensorModal.dateFrom}
              onChange={(value) =>
                this.setState({
                  sensorModal: {
                    ...this.state.sensorModal,
                    dateFrom: value,
                  },
                })
              }
              // errorMessage={this.state.sensorModalErrors.DATE_FROM?.length ? this.state.sensorModalErrors.DATE_FROM[0] : ''}
              title="Дата установки"
            />
            <DP
              value={this.state.sensorModal.dateTo}
              onChange={(value) =>
                this.setState({
                  sensorModal: {
                    ...this.state.sensorModal,
                    dateTo: value,
                  },
                })
              }
              // errorMessage={this.state.sensorModalErrors.DATE_TO?.length ? this.state.sensorModalErrors.DATE_TO[0] : ''}
              title="Дата снятия"
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.savePointSensor}>
              Сохранить
            </Button>
            <Button
              variant="secondary"
              onClick={() => this.setState({ sensorModal: {} })}
            >
              Закрыть
            </Button>
          </Modal.Footer>
        </Modal>

        <ConfirmModal {...this.state.confirmModal} />
      </>
    );
  }
}
