import React from "react";
import { Form, Button, Card, Row, Col } from "react-bootstrap";
import "../../login.css";
import conf from "../../Config";

export default class LoginForm extends React.Component {
  state = {
    serial: "0003867931DA",
    showDatails: true,
  };

  formSubmit = (event) => {
    const serial = this.state.serial.trim();
    const showDatails = this.state.showDatails;
    const url = `https://service.kvant.online/?serial=${serial}&showDetails=${showDatails}#win=mb_sensors`;
    window.open(url, '_blank').focus();
    event.preventDefault();
  };

  keyUp = (event) => {
    if (event.keyCode === 13) {
      this.formSubmit(event);
    }
  };

  onSerialInput = () => (event) => {
    this.setState({ serial: event.target.value })
  }

  onShowDetailsChange = () => (event) => {
    this.setState({ showDatails: event.target.checked })
  }

  render() {
    return (
      <>
        <div className="login-bg">
          <div className="Login">
            <Card className="login-card">
              <Card.Body>
                <Row>
                  <Col className="flex">
                    <img src={conf.logo} className="App-logo" alt="logo" />
                  </Col>
                  <Col>
                    <div className="left login-form">
                      <form onSubmit={this.formSubmit}>
                        <Form.Group>
                          <label htmlFor="login">Серийный номер датчика</label>
                          <Form.Control
                            type="text"
                            placeholder="Введите..."
                            value={this.state.serial}
                            onChange={this.onSerialInput()}
                            onKeyUp={this.keyUp}
                          />
                        </Form.Group>

                        <Form.Group>
                          <Form.Check
                            type="checkbox"
                            checked={this.state.showDatails}
                            onChange={this.onShowDetailsChange()}
                            label="Показать детализацию"
                          />
                        </Form.Group>

                        <div className="right">
                          <Button
                            variant="dark"
                            className="per100"
                            onClick={this.formSubmit}
                          >
                            Перейти
                          </Button>
                        </div>
                      </form>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </div>
        </div>
      </>
    );
  }
}
