import React from "react";
import { Form } from "react-bootstrap";

export default function DatePicker({ value, onChange, errorMessage, title, size, disabled }) {
  return (
    <Form.Group className={`search-input-obj col-md-${size ?? 4} ${disabled ? "disabled" : ""}`}>      
      <Form.Label>{title}</Form.Label>
      <Form.Control type="date" defaultValue={value ?? ""} onChange={e => onChange(e.target.value)} />
      <Form.Control.Feedback type="invalid">
        {errorMessage}
      </Form.Control.Feedback>
    </Form.Group>
  );
}
